<template>
  <v-row align="center" class="mt-2 mt-md-0">
    <v-col align-self="start" cols="12" md="12" class="pt-md-0">
    </v-col>
    <v-col cols="12" class="d-block d-md-none text-center pb-0">
      <v-chip color="light-green accent-4" text-color="white" class="mb-1" style="font-weight: bold; font-size: 17px;">
        {{ departureCityName }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ arrivalCityName }}
      </v-chip>
    </v-col>
    <v-col cols="12" class="d-block d-md-none text-center pt-1">
      <v-chip>
        <v-icon class="mr-1">mdi-clock-time-ten-outline</v-icon>
        <span>{{ flightTime | flyingTime }}</span>
      </v-chip>
    </v-col>
    <station :data="value.departure" :dateFormat="dateFormat" :timeFormat="timeFormat" direction="departure"
             :airportsData="airportsData"/>
    <v-col class="d-none d-sm-block d-md-none">
      <v-row justify="center" align="center">
        <arrow></arrow>
      </v-row>
    </v-col>
    <v-col md="2" class="text-center d-none d-md-block mt-3">
<!--      <div class="mb-3 mb-md-1">-->
<!--        {{ flightTime | flyingTime }}-->
<!--      </div>-->
      <v-row justify="center" align="center">
        <arrow></arrow>
      </v-row>
      <div class="mb-3 mb-md-1">
        {{ value.flightNumber }}
      </div>
    </v-col>
    <station :data="value.arrival" :dateFormat="dateFormat" :timeFormat="timeFormat" direction="arrival"
             :airportsData="airportsData"/>
    <v-col cols="12" class="d-block d-md-none text-center py-0 text-body-1">
      <v-row justify="center">
        <v-col class="text-center">
          <v-divider></v-divider>
          {{ departureAirport }}
        </v-col>
        <v-col class="text-center">
          <v-divider></v-divider>
          {{ arrivalAirport }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Arrow from "@/components/UI/Arrow.vue";
import {format} from "date-fns";
import Station from "../UI/Station.vue";

export default {
  name: "FlightRoute",
  components: {
    Arrow,
    Station,
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
    companiesData: {
      type: Array
    },
    dateFormat: {
      type: String,
    },
    timeFormat: {
      type: String,
    },
    airportsData: {
      type: Map,
    },
  },
  filters: {
    flyingTime(v) {
      if (!v) return v;
      if (v.indexOf(":") < 0) return v;
      return v.replace(":", "ч ") + "м";
    },
  },
  methods: {
    //функция получает количество минут и возвращает время формата hh:mm,
    MinsToHoursAndMins(mins) {
      //преобразуем строку в число
      mins = parseInt(mins);
      //вычислим целое кол-во часов в минутах
      let hoursAnswer = Math.floor(mins / 60);
      //вычислим оставшиеся минуты
      let minsAnswer = mins % 60;
      //добавим 0, если это необходимо
      if (hoursAnswer < 10) {
        hoursAnswer = "0" + hoursAnswer;
      }
      if (minsAnswer < 10) {
        minsAnswer = "0" + minsAnswer;
      }
      return hoursAnswer + ":" + minsAnswer;
    },
    FormatMethod(date, formatData) {
      return format(date, formatData);
    }
  },
  computed: {
    flightNumber() {
      let carrierCode = this.value.carrier
      let flightNumber = this.value.flightNumber
      return (carrierCode+flightNumber)
    },
    departureCityName() {
      let departureName = this.value.departure.name;
      return departureName.substring(0, departureName.indexOf('('));
    },
    arrivalCityName() {
      let arrivalName = this.value.arrival.name;
      return arrivalName.substring(0, arrivalName.indexOf('('));
    },
    flightTime() {
      return this.value.travelTime.substring(0, 5);
    },
    departureTime() {
      return this.FormatMethod(new Date(this.value.departure.date), this.timeFormat)
    },
    departureDate() {
      return this.FormatMethod(new Date(this.value.departure.date), this.dateFormat);
    },
    departureAirport() {
      let departureName = this.value.departure.name;
      return departureName.substring(departureName.indexOf('(') + 1, departureName.indexOf(')'))
    },
    arrivalTime() {
      return this.FormatMethod(new Date(this.value.arrival.date), this.timeFormat)
    },
    arrivalDate() {
      return this.FormatMethod(new Date(this.value.arrival.date), this.dateFormat)
    },
    arrivalAirport() {
      let arrivalName = this.value.arrival.name;
      return arrivalName.substring(arrivalName.indexOf('(') + 1, arrivalName.indexOf(')'));
    }
  }
};
</script>

<style>

</style>
