<template>
  <div>
    <v-autocomplete v-model="city"
                    :items="cities"
                    :search-input.sync="searchInputCity" return-object
                    :rules="cityRules"
                    :label="label"
                    required ref="input_from">
    </v-autocomplete>
  </div>

</template>

<script>

export default {
  name: "InputTown",

  props: {
    IsRailway: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    start: {
      value:{
        type:String,
        required:false,
        default:''
      }
    },
    label: {
      value:{
        type:String,
        required: false,
        default:''
      }
    },
    endpoint: {
      type: String,
      default: "/Data/Stations/Get"
    },
  },
  model: {
    prop: ["value"],
    event: ["change-value"],
  },
  emits: ['change-value'],

  data() {
    return {
      city: null,
      searchInputCity: null,
      arrayCities: [],
      cityRules: [(v) => !!v || "Выберите город"],
      timerFilter: null,

    };
  },
  async mounted() {
    if (this.start === '') return

    let city = await this.getCityByCode(this.start);
    console.log(city.text);
    this.cities = [{
      text: `${city.text}`,
      value: {
        code: `${city.value.code}`,
        name: `${city.value.name}`
      },
    }]
    this.city = {
      text: `${city.text}`,
      value: {
        code: `${city.value.code}`,
        name: `${city.value.name}`
      },
    }
  },

  computed: {
    cities: {
      get() {
        return this.sortCitiesArray(this.arrayCities, this.searchInputCity);
      },
      set(newValue) {
        this.arrayCities = newValue;
      },
    },

  },


  watch: {
    city(val) {
      this.$emit("change-value", val);
    },
    searchInputCity: {
      handler() {
        clearTimeout(this.timerFilter);
        this.timerFilter = setTimeout(async () => {
          this.cities = await this.getStations(this.searchInputCity);
          console.log(this.cities[0].text);
          const isMoscow = this.cities.find((e) => e.text == "Москва")
          const index = this.cities.findIndex((e) => e.text == "Москва");
          if (index != -1) {
            this.cities.splice(0, index, isMoscow);
          }
        }, 300);
      }
    },

    value: {
      handler(newItem) {
        this.cities = [newItem]
        this.city = newItem
      },
      deep: true,
    },
  },


  methods: {
    sortCitiesArray(array, val) {
      return array.sort(function (a, b) {
        let first = a.text.slice(0, val.length).toLowerCase();
        let second = b.text.slice(0, val.length).toLowerCase();
        if (first === val && second !== val) {
          return -1;
        } else if (first !== val && second === val) {
          return 1;
        } else {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
        }
        return 0;
      });
    },

    async getStations(val) {
      if (val === null || val === "") return [];
      let stations = [];
      let url = this.endpoint;
      if (val.length >= 2) {
        let resp = await this.$axios.get(url + `?text=${val}`);

        if (this.IsRailway) {
          resp.data.forEach((e) => {
            stations.push({
              text: e,
              value: {
                code: e,
                name: e,
              },
            });
          });
          console.log(stations)
          return stations;
        } else {
          resp.data.forEach((e) => {
            stations.push({
              text: e.nameRu,
              value: {
                code: e.codeEn,
                name: e.nameRu,
              },
            });
          });
          return stations
        }
      }
      return stations;
    },

    async getCityByCode(val) {
      let station;
      let result = await this.$axios.get(
          this.$enums.Endpoints.Data.Station + `?text=${val}`
      );
      station = {
        text: result.data.nameRu.replace(/\(.+\)/, ''),
        value: {
          code: result.data.codeEn,
          name: result.data.nameRu.replace(/\(.+\)/, ''),
        },
      }
      return station;
    },

  },
}
</script>

<style>
.v-autocomplete input {
  font-size: 1.2rem;
}
</style>
