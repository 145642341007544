<template>
  <v-row :id="id">
    <v-col>
      <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 4 : 1" class="titleCard px-5 pb-4 my-5"
          :class="colorIndex % 2 === 0 ? 'item1' : 'item2'">
          <v-chip v-if="$vuetify.breakpoint.mdAndUp" class="on-border-top" text-color="white"
            :color="transfersCount > 0 ? 'orange' : 'green'">
            <span v-if="transfersCount > 0">
              {{ transfersInfo }}
            </span>
            <span v-else> Прямой </span>
          </v-chip>
          <v-row>
            <v-col cols="12" md="10">
              <v-row no-gutters>
                <v-col cols="12" class="text-center text-md-left d-flex">
                  <v-img max-height="30" max-width="30" :src="companyImgUrl.imgUrl"></v-img>
                  <span class="text-h6 pl-2">
                    {{ carrierName }}
                  </span>
                  <span class="text-h6 pl-2">
                    {{ fligthNumber }}
                  </span>
                </v-col>
              </v-row>
              <div v-for="(flight, i) in value.routes" :key="i">
                <v-fade-transition>
                  <v-row v-show="animation">
                    <flight-direction class="mb-5" :value="flight" :pult="value.Pult" :transfers="hasStops"
                      :companiesData="companiesData" :airportsData="airportsData" :dateFormat="dateFormat"
                      :timeFormat="timeFormat" :areTransfersShow="!showDetails && hasStops"></flight-direction>
                  </v-row>
                </v-fade-transition>
                <v-divider v-if="$vuetify.breakpoint.xs" class="my-0"></v-divider>
              </div>
              <v-row no-gutters class="mt-1">
                <v-chip v-if="value.charterOrLowcost" color="purple">{{ value.charterOrLowcost }}</v-chip>
                <info-chip title="Возврат" :value="value.refund" class="ml-2"></info-chip>
                <info-chip title="Багаж" :value="value.baggage" class="ml-2">
                </info-chip>
                <info-chip v-if="$store.getters.user.role !== $enums.Roles.SubAgent" title="Provider"
                  :value="value.provider" class="ml-2">
                </info-chip>
                <info-chip v-if="$store.getters.user.role !== $enums.Roles.SubAgent" title="GDS" :value="value.gds"
                  class="ml-2">
                </info-chip>
              </v-row>
            </v-col>
            <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical class="my-2"></v-divider>
            <v-col md="2" class="pr-md-0 pt-sm-3 pt-0">
              <v-row>
                <v-col v-if="$vuetify.breakpoint.xs" cols="2" class="text-left" align-self="center">
                  <v-btn v-if="hasStops" elevation="0" color="primary" small fab outlined @click="toggleDetails">
                    <v-icon v-if="showDetails">la-angle-down</v-icon>
                    <v-icon v-else>la-angle-up</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="10" md="12" class="text-right pr-0 pr-sm-3">
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-1">
                      <v-btn :width="$vuetify.breakpoint.smAndUp ? '100%' : ''" dark elevation="0" color="orange"
                        @click="$emit('Buy', value)">
                        Выбрать
                      </v-btn>
                    </v-col>
                    <v-col class="py-2 pb-0" cols="12">
                      <div class="text-h5 d-inline-block font-weight-bold ">
                        {{ value.price.total }} РУБ
                      </div>
                      <!-- <div class="text-h5 d-inline-block font-weight-bold" :class="ifCheapGreen
                        ? 'green--text'
                        : ifNormalOrange
                          ? 'orange--text'
                          : 'red--text'
                        ">
                        {{ value.price.total }} РУБ
                      </div> -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="alert-container" v-if="mayNotVoidable">
                <div class="alert">
                  <span class="alert-content">
                    <v-icon color="warning">mdi-alert</v-icon>
                    Возможно, аннуляция недоступна
                  </span>
                </div>
              </div>
            </v-col>
            <icon-button v-if="$vuetify.breakpoint.smAndUp && hasStops" size="33" :small="true" color="primary"
              class="on-border-bottom" :iconName="showDetails
                ? 'mdi-chevron-down-circle'
                : 'mdi-chevron-up-circle'
                " @click="toggleDetails"></icon-button>
          </v-row>
        </v-card>
      </v-hover>
    </v-col>
    <fare-remarks-modal v-model="fareRemarkModel" :text="fareRemarkText" :loading="fareRemarkLoading">
    </fare-remarks-modal>
  </v-row>
</template>

<script>
import Arrow from "@/components/UI/Arrow.vue";
import ErrorModal from "@/components/UI/ErrorModal.vue";
import FlightDirection from "@/components/FoundedTicketsPage/FlightDirection.vue";
import FareRemarksModal from "@/components/FoundedTicketsPage/FareRemarksModal.vue";
import ApiCalls from "@/mixins/booking/ApiCalls.js";
import IconButton from "../UI/IconButton.vue";
import InfoChip from "@/components/UI/InfoChip.vue";

export default {
  name: "TicketsListItem",
  components: {
    Arrow,
    ErrorModal,
    FareRemarksModal,
    FlightDirection,
    IconButton,
    InfoChip,
  },
  mixins: [ApiCalls],
  props: {
    value: {
      type: Object,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
    maxPrice: {
      type: Number,
    },
    minPrice: {
      type: Number,
    },
    companiesData: {
      type: Array,
    },
    airportsData: {
      type: Map,
    },
  },

  data() {
    return {
      showDetails: true,
      animation: true,
      id: null,
      fareRemarkModel: false,
      fareRemarkLoading: false,
      fareRemarkText: null,
      dateFormat: "yyyy-MM-dd",
      timeFormat: "HH:mm",
    };
  },
  mounted() { },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
      this.animation = false;
      setTimeout(() => {
        this.animation = true;
        this.$vuetify.goTo("#" + this.id);
      }, 100);
    },
    stopsTitle(num) {
      num = Number(num);
      return num % 100 > 10 && num % 100 < 20
        ? "пересадок"
        : num % 10 === 1
          ? "пересадка"
          : num % 10 > 1 && num % 10 < 5
            ? "пересадки"
            : "пересадок";
    },
  },
  computed: {
    fligthNumber() {
      const num = this.value.routes[0].segments[0].flightNumber
      if (num.includes(this.carrierCode))
        return num;
      return this.carrierCode + "-" + num;
    },
    companyImgUrl() {
      let companyData = this.companiesData.find(
        (company) => company.code === this.value.routes[0].segments[0].carrier
      );
      return companyData === undefined ? { imgUrl: "" } : companyData;
    },
    carrierName() {
      let companyName = this.companiesData.find(
        (company) => company.code === this.value.routes[0].segments[0].carrier
      );
      return companyName === undefined
        ? this.value.routes[0].segments[0].carrier
        : companyName.name;
    },
    carrierCode() {
      let _company = this.companiesData.find(
        (company) => company.code === this.value.routes[0].segments[0].carrier
      );
      return _company === undefined
        ? this.value.routes[0].segments[0].carrier
        : _company.code;
    },
    transfersCount() {
      let transfersCount = 0;
      for (let i = 0; i < this.value.routes.length; i++) {
        transfersCount += this.value.routes[i].segments.length - 1;
      }
      return transfersCount;
    },
    hasStops() {
      for (let i = 0; i < this.value.routes.length; i++) {
        if (this.value.routes[i].segments.length > 1) {
          return true;
        }
      }
      return false;
    },
    transfersInfo() {
      return this.transfersCount + " " + this.stopsTitle(this.transfersCount);
    },
    ifCheapGreen() {
      return (
        this.value.price.total <
        (this.maxPrice - this.minPrice) * 0.25 + this.minPrice ||
        this.value.price.total < this.minPrice * 1.25
      );
    },
    ifNormalOrange() {
      return (
        this.value.price.total <
        (this.maxPrice - this.minPrice) * 0.55 + this.minPrice ||
        this.value.price.total < this.minPrice * 1.55
      );
    },
    mayNotVoidable() {
      return (
        this.value.provider == 'CityTravel' || this.value.routes[0].segments[0].carrier == 'SU');
    },
  },
  filters: {},
};
</script>

<style scoped>
.titleCard {
  border: solid 1px orange !important;
}

.on-border-bottom {
  top: +9px;
  left: -21px;
  /* left: -2.4%; */
}

.on-border-top {
  top: -17px;
  right: -10px;
}

.alert-container {
  display: flex;
  flex-direction: column;
  height: 90%;
  padding-bottom: 20px;
}

.alert {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.alert-content {
  color: orange;
  font-size: 20px;
}
</style>
